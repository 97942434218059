/* a,
a:hover,
a:visited,
a:active {
  font-weight: 800;
  font-weight: 600 !important;
  color: white !important;
} */

button, .spunButton {
  border: 0px;
  border-radius: 0px;
  min-width: 161px;
  background-color: #271c2c !important;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 4px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #e83182;
  margin-top: 18px;
  font-weight: 800;
  font-weight: 600 !important;
  color: white !important;
  padding-top: 9px;
  padding-bottom: 9px;
}

body {
  font-size: large;
}
